import { Backdrop, IconButton, makeStyles } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
	Box,
	CircularProgress,
	Container,
	Divider,
	InputAdornment,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/Authentication/hooks/useAuth";
import { LoginData } from "../../../models/Login";
import { postLogin } from "../../../services/login";
import "./styles.scss";

function FormularioLoginView(): React.ReactElement {
	const theme = useTheme();
	const navigate = useNavigate();
	const { setAuth } = useAuthContext();
	const [showPassword, setShowPassword] = React.useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ mode: "onSubmit" });
	// const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
	const useStyles = makeStyles((theme) => ({
		textField: {
			"& label.Mui-focused": {
				color: "black",
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: "Grey",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: "Grey",
				},
				// '&:hover fieldset': {
				//   borderColor: 'blue',
				// },
				"&.Mui-focused fieldset": {
					borderColor: "Grey",
				},
			},
		},
	}));
	const classes = useStyles();

	/**
	 * @function handleSignUp
	 * Faz uma mudança de rotas para /signup.
	 */
	// function handleSignUp() {
	//   navigate("/signup");
	// }

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const [open, setOpen] = useState<boolean>(false);

	/**
	 * @function login
	 * Faz a chamda da função postLogin passando data por paramentro, caso a requisição der certo seta data recebido da requisição no AuthContext, seta um valor no sessionStorage com o nome da chave de token e faz uma mudança de rota para /.
	 * @param {LoginData} data -
	 */
	async function login(data: LoginData) {
		setOpen(true);
		postLogin(data)
			.then((data) => {
				localStorage.setItem("@app-jobs:token", JSON.stringify(data));
				setAuth(data);
				navigate("/");
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => setOpen(false));
	}

	return (
		<Fragment>
			<Backdrop open={open} style={{ zIndex: 10000 }}>
				<CircularProgress />
			</Backdrop>
			<div className="divLogin" data-testid="formulario-login">
				<div className="divPrancheta">
					<Typography className="pranchetaHeader">
						Conecte-se com mais de 30 mil talentos
						<br /> em todo o Brasil
					</Typography>
					<img
						alt="aiamis"
						src="https://dirin.s3.amazonaws.com/ecossistema_v5/assets/search-rh_Pranchet.svg"
						className="imgPrancheta"
					/>
					<img
						alt="Instituições"
						src="img/Logos Instituições b-01.svg"
						className="b-01Prancheta"
					/>
				</div>
				<Container component="section" className="containerLogin">
					<Box
						sx={{
							m: 3,
							display: "flex",
							gap: "12px",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{/* <div className="divAiamis"> */}
						<img
							src={
								theme.palette.mode === "dark"
									? "./img/Logo 06.png"
									: "./img/Logo 02.png"
							}
							alt=""
							height="120"
							style={{ paddingTop: "12px" }}
						/>
						{/* <img
              alt="aiamis"
              src="img/aiamis_Prancheta_1.svg"
              className="imgAiamis"
              style={{
                width: "25%",
              }}
            />
            <Divider orientation="vertical" /> */}
						{/* <h1 className="spanOportunidades">&nbsp; | Oportunidades </h1> */}
						{/* <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
              {" "}
              <strong> Oportunidades </strong>{" "}
            </Typography> */}
						{/* </div> */}
					</Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							flexDirection: "column",
							justifyContent: "center",
							height: "25vh",
							// border: "solid purple",
						}}
					>
						<Typography variant="h5" component="h5">
							Anuncie suas vagas
						</Typography>
					</Box>

					<Box>
						<Typography
							variant="body1"
							component="p"
							textAlign="center"
						>
							Entrar com e-mail
						</Typography>
						<form
							onSubmit={handleSubmit((data) =>
								login({
									email: data.email,
									password: data.password,
								})
							)}
						>
							<div>
								<Typography className="titleCamp">
									Insira seu email*
								</Typography>
								<TextField
									className={classes.textField}
									{...register("email", { required: true })}
									type="email"
									// variant="outlined"
									fullWidth
									margin="none"
									placeholder="Ex: raquel@gmail.com"
									error={errors.email?.type === "required"}
									helperText={
										errors.email?.type === "required" && (
											<span>
												O campo "E-mail" é obrigatório!
											</span>
										)
									}
								/>
							</div>
							<div style={{ marginTop: "10px" }}>
								<Typography className="titleCamp">
									Senha*
								</Typography>
								<TextField
									className={classes.textField}
									// type="password"
									type={showPassword ? "text" : "password"}
									placeholder="Insira aqui sua senha"
									margin="none"
									variant="outlined"
									fullWidth
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={
														handleClickShowPassword
													}
													onMouseDown={(e) => {
														e.preventDefault();
													}}
													edge="end"
												>
													{showPassword ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
									{...register("password", {
										required: true,
									})}
									error={errors.password?.type === "required"}
									helperText={
										errors.password?.type ===
											"required" && (
											<span>
												O campo "Senha" é obrigatório!
											</span>
										)
									}
								/>
							</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-end",
								}}
							>
								{/* <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox {...label} sx={{
                                    color: "#B50000",
                                    '&.Mui-checked': {
                                        color: "#B50000",
                                    },
                                }} />
                                <span className="spanConect">
                                    Matenha-me conectado
                                </span>
                            </div> */}

								<Link
									to="/access-recovery/solicitation"
									style={{
										textDecoration: "none",
										marginTop: "5px",
									}}
								>
									<Typography
										className="spanForget"
										style={{ color: "#B50000" }}
									>
										Esqueceu a senha?
									</Typography>
								</Link>
							</div>
							<Button
								type="submit"
								variant="contained"
								disableElevation
								size="large"
								fullWidth
								sx={{
									mt: 3,
									mb: 3,
								}}
								// className="buttonLogIn"
							>
								Entrar
							</Button>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									margin: "20px 0px 0px 0px",
								}}
							>
								<span>
									Não possui cadastro?{" "}
									<Link
										to="/signup"
										style={{
											color: "black",
											textDecoration: "none",
											fontWeight: "bold",
										}}
									>
										Inscrever-se no UNINTA Jobs
									</Link>
								</span>
							</div>
						</form>
						<Divider />
						<Link to="/login-aluno">
							<Button
								variant="outlined"
								fullWidth
								sx={{
									mt: 3,
								}}
							>
								Continuar como aluno
							</Button>
						</Link>
					</Box>
				</Container>
			</div>
		</Fragment>
	);
}

export default FormularioLoginView;
