import { AtualizarUsuario, CriarUsuario, RespostaUsuario, Usuario } from "../../models/Usuario";
import Notify from "../../utils/notification";
import api from "../api";

/**
 * @function postUsuario
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo post para a rota /users, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaUsuario> contendo duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array com um elemento que contém os dados do usuário cadastrado. 
 * @param {CriarUsuario} data - Dados do usuário a ser cadastrado.
 */

export async function postUsuario(data: CriarUsuario): Promise<RespostaUsuario> {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  let dados = {
    name: data.name,
    email: data.email,
    password: data.password,
    is_active: data.is_active,
    role_id: data.role_id
  }

  return await api.post("/users", dados, { headers: { "Authorization": `Bearer ${token}` } }).then((data) => {
    Notify("Usuário cadastrado com sucesso!");
    return data;
  }).catch((e: any) => {
    Notify("Usuário não foi cadastrado, verifique seus dados e tente novamente!", "error");
    return e;
  });

}

/**
 * @function getUsuarios
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /users/:pagina, caso o parâmetro página não seja nulo, caso não, a requisição do tipo get é feita para a rota /users, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaUsuario> contendo duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array com todos os usuários disponíveis na base de dados.
 * @param {string | null} pagina - Número da página escolhida para a paginação.
 */

export async function getUsuarios(pagina: string | null, role: string|null): Promise<RespostaUsuario> {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.get(`/users?page=${pagina === null ? '1' : pagina}&limit=10${role ? '&role=' + role : ''}`, { headers: { "Authorization": `Bearer ${token}` } })
    .then(({ data }) => {
      return data;
    }).catch((e: any) => {
      return e;
    });

}

export async function getAllUsuarios() {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.get('/users', { headers: { "Authorization": `Bearer ${token}` } })
    .then(({ data }) => {
      return data;
    }).catch((e: any) => {
      return e;
    });

}

/**
 * @function getUsuario
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /users/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<Usuario> contendo os dados dum usuário específico associado ao id.
 * @param {number} id - Id do usuário a ser buscado.
 */

export async function getUsuario(id: number): Promise<Usuario> {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.get(`users/${id}`, { headers: { "Authorization": `Bearer ${token}` } })
    .then(({ data }) => {
      return data;
    }).catch((e: any) => {
      return e;
    });

}

/**
 * @function putUsuario
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo put para a rota /users/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso nada é retornado pela api.
 * @param {number} id - Id do usuário a ser atualizado.
 * @param {AtualizarUsuario} data - Dados do usuario a ser atualizado.
 */

export async function putUsuario(id: number, data: AtualizarUsuario) {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.put(`/users/${id}`, data, { headers: { "Authorization": `Bearer ${token}` } }
  ).then(() => {
    Notify("Usuário alterado com sucesso!");
  }).catch((e) => {
    Notify("Usuário não foi alterado, verifique seus dados e tente novamente!", "error");
    return e;
  });
}

/**
 * @function deleteUsuario
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo delete para a rota /users/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso nada é retornado.
 * @param {number} id - Id do usuário a ser deletado.
 */

export async function deleteUsuario(id: number) {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.delete(`/users/${id}`, { headers: { "Authorization": `Bearer ${token}` } })
    .catch((e) => {
      return e;
    });

}